<template>
  <div class="introduction clearfix">
    <div class="pull-left">
      <span><img :src="baseUrl + (detail.logoUrl || detail.photoUrl)" /></span>
      <div>
        <div><h3>{{ detail.organizationName || detail.teacherName }}</h3><a-button type="outline">{{ $route.name == 'institutionDetail' ? '培训机构' : '讲师' }}</a-button></div>
        <div class="content" v-html="showChange(detail.organizationInfo || detail.teacherInfo)"></div>
      </div>
    </div>
    <div class="pull-right">
      <h2>{{ detail.courseNumber }}</h2>
      <p>发布课程</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['detail'],
  methods: {
    showChange(item) {
      if(item && (/\r\n/g.test(item) || /\n/g.test(item) || /(\r\n)|(\n)/g.test(item) || /\s/g.test(item) )) {
        item = item.replace(/\r\n/g,"<br>")
        item = item.replace(/\n/g,"<br>");
        item = item.replace(/(\r\n)|(\n)/g,'<br>');
        item = item.replace(/\s/g,"&nbsp;");
      }
      return item
    }
  }
}
</script>

<style lang='scss'>
  .introduction {
    width: 1180px;
    background: #fafafa;
    padding: 30px 20px 40px;
    margin: 20px auto 30px;

    .pull-left {
      width: calc(100% - 220px);
      display: flex;

      & >span {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        overflow: hidden;
        background: #fff;
        margin-right: 20px;
        flex-shrink: 0;

        & > img {
          width: 100%;
          height: 100%;
          
        }
      }

      & > div {
        & > div {
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          & > h3 {
            font-size: 18px;
            color: #303133;
            line-height: 24px;
            margin-right: 15px;
          }

          .arco-btn {
            height: 24px;
            border-radius: 4px;

            cursor: initial;
            font-size: 12px;
            padding: 0 12px;
          }
        }

        .content {
          font-size: 14px;
          color: #606266;
          line-height: 24px;
        }
      }
      
    }

    .pull-right {
      width: 180px;
      margin-left: 40px;
      font-size: 14px;
      text-align: center;
      color: #1d2129;
      padding-top: 20px;

      & > h2 {
        font-size: 60px;
        font-family: Impact, Impact-Regular;
        font-weight: 400;
        color: #05a081;
        line-height: 74px;
        margin-bottom: 10px;
        padding: 0;
        text-align: center;
      }
    }
  }
</style>