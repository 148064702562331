<template>
  <div id="secondDetail" v-wechat-title='title'>
    <CommonBread></CommonBread>
    <Introduction :detail="detail"></Introduction>
    <div class="common_layout">
      <div class="layout_left">
        <CommonListCom :show="show" :typeList="typeList" @change="changeClick" @clear="clearClick">
          <h3>共找到 <span>{{ totalNum }}</span> 个课程</h3>
        </CommonListCom>
        <CourseList :dataList="dataList"></CourseList>
        <CommonPagination
        :pageNum="pageNum"
        :pageSize="pageSize"
        :totalNum="totalNum"
        placement="right"></CommonPagination>
      </div>
      <div v-if="detail.organization || detail.teacherList && detail.teacherList.length > 0" class="layout_right">
        <CourseTeacher :organization="detail.organization || {}" :teacherList="detail.teacherList || []" :type=" $route.name == 'teacherDetail' ? 'institution' : null" :title="$route.name == 'institutionDetail' ? '机构讲师' : '所属机构'"></CourseTeacher>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread.vue'
import CommonPagination from '@/components/CommonPagination.vue'
import CommonListCom from '@/components/CommonListCom.vue'
import CourseList from './components/CourseList.vue'
import Introduction from './components/Introduction.vue'
import CourseTeacher from './components/CourseTeacher.vue'
import merge from 'webpack-merge'
export default {
  components: {
    CommonBread,
    CommonListCom,
    CommonPagination,
    CourseList,
    Introduction,
    CourseTeacher
  },
  data() {
    return {
      pageNum: parseInt(this.$route.query.pager) || 1,
      pageSize: 15,
      totalNum: 0,
      typeList: [
        { title: '最新发布', type: 1, key: 'publish' },
        { title: '价格', type: 2, key: 'priceSort' },
      ],
      dataList: [],
      detail: {},
      search: {},
      searchStatus: false,
      show: false,
      title: '广东省碳达峰碳中和标准化技术信息公共服务平台'
    }
  },
  mounted() {
    this.showPage()
    this.coursePage();
  },
  methods: {
    coursePage() {
      let api = this.$route.name == 'institutionDetail' ? 'InstitutionDetail' : 'TeacherDetail'
      this.$API[api]({
        id: parseInt(this.$route.params.id)
      }, 3).then(res => {
        if(res.data.code == 200) {
          this.detail = res.data.data
          this.title = `${res.data.data.organizationName || res.data.data.teacherName} - 广东省碳达峰碳中和标准化技术信息公共服务平台`
        }else {
          this.$router.replace('/404')
        }
      })
    },
    showPage() {
      this.$API.TrainingCoursesList({
        organizationId: this.$route.name == 'institutionDetail' ? parseInt(this.$route.params.id) : '',
        priceSort: parseInt(this.search.priceSort || ''),
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        teacherId: this.$route.name == 'teacherDetail' ? parseInt(this.$route.params.id) : '',
      }).then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.totalNum = res.data.data.total
        }
      })
    },
    changeClick(data) {
      this.search.priceSort = null;
      this.search[data.key] = data.type;
      this.pageNum = 1;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.show = true;
      this.showPage();
    },
    clearClick() {
      this.search = {};
      this.pageNum = 1;
      this.$router.replace({
        query: merge(this.$route.query, { pager: 1 }),
      });
      this.show = false;
      this.showPage();
    }
  }
}
</script>

<style lang='scss'>
  #secondDetail {

  }
</style>