<template>
  <div class="common_list_com">
    <div class="list_type clearfix">
      <div 
        :class="[{ 'active': active == index }, 'item pull-left']" 
        v-for="(item, index) in typeList" 
        :key="item.key"
        @click="indexClick(index)">
        <span class="pull-left">
          <template v-if="item.type == 1">{{ item.title }}<icon-arrow-down class="normal" /></template>
          <template v-if="item.type == 2">{{ item.title }}
            <span>
              <icon-caret-up :class="{'icon_active': active == index && dataType == 'up'}" />
              <icon-caret-down :class="{'icon_active': active == index && dataType == 'down'}" />
            </span>
          </template>
        </span>
      </div>
      <div class="pull-right"><slot name="right"></slot></div>
    </div>
    <div class="show_num clearfix">
      <div class="pull-left">
        <slot></slot>
      </div>
      <div v-if="show" class="pull-right">
        <a-link v-if="!clear" @click="clearClick" href="javascript:void(0);"><svg-icon icon-class="clean"></svg-icon>清除搜索条件</a-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['typeList', 'clear', 'show'],
  data() {
    return {
      active: 0,
      dataType: null,
      clearShow: false
    }
  },
  methods: {
    indexClick(index) {
      if(index !== this.active) this.dataType = null;
      this.active = index;
      if(index != 0) {
        if(!this.dataType) this.dataType = 'up'
        else if(this.dataType == 'up') this.dataType = 'down'
        else if(this.dataType == 'down') this.dataType = 'up'
      }
      this.$emit('change', {
        key: this.typeList[index].key,
        type: this.dataType == 'up' ? '1' : '0',
        index: index
      })
    },
    clearClick() {
      this.active = 0; 
      this.dataType = null;
      this.$emit('clear')
    }
  }
}
</script>

<style lang='scss'>
  .common_list_com {
    .list_type {
      margin: 0 10px;
      border-bottom: 2px solid #05A081;
      height: 49px;
      // display: flex;


      .item {
        padding: 14px 20px;
        font-size: 14px;
        line-height: 20px;
        color: #606266;
        cursor: pointer;
        

        &:hover {
          background: #05A081;
          color: #fff;
        }

        & > span {
          display: flex;
          align-items: center;

          & > .arco-icon {
            margin-left: 5px;
          }
          
          & > span {
            position: relative;
            width: 20px;
            display: inline-block;

            .arco-icon {
              display: block;
              font-size: 12px;
              margin-left: 5px;
              position: absolute;
              
              &:first-child {
                top: -8px;
              }
              &:last-child {
                top: -2px;
              }
            }

            .icon_active {
              color: rgba(255, 255, 255, 1)
            }
          }
        }
      }

      .active {
        background: #05A081;
        color: #fff;

        .arco-icon {
          color: rgba(255, 255, 255, 0.6);
        }

        .icon_active,
        .normal {
          color: rgba(255, 255, 255, 1)
        }
      }
    }

    .show_num {
      height: 72px;
      padding: 20px 0;
      margin: 0 10px;
      border-bottom: 1px solid #DCDFE6;
      
      h3 {
        font-size: 18px;
        line-height: 32px;

        & >span {
          color: #05A081;
          margin: 0 5px;
        }
      }
      .arco-link {
        width: 132px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        color: #606266;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;

        .svg-icon {
          width: 18px;
          height: 18px;
          margin-right: 5px;
          color: #606266;
        }
      }
    }

    .arco-checkbox {
      padding: 14px 10px;
    }
  }
</style>