<template>
  <a-modal 
    :visible="visible" 
    :unmount-on-close="true"
    :footer="false"
    modal-class="relation_modal"
    title=""
    width="400"
    @cancel="$emit('close')">
    <template #title> </template>
    <div class="top clearfix">
      <div class="pull-left">
        <h1>联系我们</h1>
        <p>Contact Us</p>
      </div>
      <img class="pull-right" src="../../../assets/img/contact.png" />
    </div>
    <a-divider />
    <a-descriptions :data="data" align="left" :column="1">
      <a-descriptions-item v-for="line in data" :key="line.key" :label="line.name">
        {{ (detail && detail[line.key]) || '-' }}
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>
export default {
  props: ['visible', 'detail'],
  data() {
    return { 
      data: [
        { name: '联系人', key: 'contact' },
        { name: '联系电话', key: 'contactPhone' },
        { name: '联系邮箱', key: 'contactEmail' },
      ]
    }
  },
}
</script>

<style lang='scss'>
.relation_modal {
  text-align: left;

  .arco-modal-header {
    height: 48px;
  }

  .arco-modal-body {
    padding:  0 16px 50px;

    .top {
      padding: 18px 10px 10px;
      
      .pull-left {
        h1 {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 5px;
          margin-top: 7px;
        }

        p {
          font-size: 14px;
          color: #4e5969;
        }
      }

      img {
        width: 100px;
        height: 70px
      }
    }

    .arco-descriptions-size-medium .arco-descriptions-item-label-block {
      padding: 5px 10px 5px 10px;
      width: 90px;
      color: #606266;
    }
    .arco-descriptions-size-medium .arco-descriptions-item-value-block {
      padding: 5px 10px 5px 0;
      color: #86909c;
    }
    .arco-descriptions-table tr {
      line-height: 20px;
      font-size: 14px;
      color: #606266;
    }
  }
}


</style>