<template>
  <div class="course_list">
    <template v-if="dataList.length > 0">
      <div class="item" v-for="item in dataList" :key="item.id">
        <div class="item_left" @click="detailClick(item.id)"><img :src="baseUrl + item.coverUrl" /></div>
        <div class="item_center">
          <h3 @click="detailClick(item.id)">{{ item.courseName }}</h3>
          <p v-if="item.applyEnd"><svg-icon icon-class="time"></svg-icon>{{ item.applyEnd }} 截止报名</p>
          <p v-else-if="item.longTime == 1"><svg-icon icon-class="time"></svg-icon> 报名长期有效</p>
          <p><svg-icon icon-class="sort"></svg-icon>{{ item.courseSort }}</p>
          <p>
            <svg-icon icon-class="building"></svg-icon>
            <router-link to="/">{{ item.organizationName }} </router-link>
            <a-link href="javascript:void(0);" @click="visible = true; detail = item">咨询</a-link>
          </p>
        </div>
        <div class="item_right">￥ {{ item.coursePrice }} /人</div>
      </div>
    </template>
    <a-empty v-else />
    <RelationModal :visible="visible" :detail="detail" @close="visible = false; detail = null"></RelationModal>
  </div>
</template>

<script>
import RelationModal from './RelationModal'
export default {
  components: {
    RelationModal
  },
  props: ['dataList'],
  data() {
    return {
      visible: false,
      detail: null
    }
  },
  methods: {
    detailClick(id) {
      window.open(`/trainingCoursesDetail/${id}`)
    }
  }
}
</script>

<style lang='scss'>
  .course_list {
    .item {
      display: flex;
      padding: 30px 10px;

      &:hover {
        background: #fafafa;
      }

      .item_left {
        width: 210px;
        height: 140px;
        margin-right: 20px;
        overflow: hidden;

        & > img {
          width: 210px;
          height: 140px;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
      
      .item_center {
        width: 470px;
        margin-right: 20px;

        & > h3 {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 15px;
          cursor: pointer;
          width: 100%;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;

          &:hover {
            color: #05A081;
          }
      }

        & > p {
          font-size: 14px;
          color: #86909c;
          line-height: 20px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;

          a {
            color: #86909c;
            text-decoration: none;

            &:hover {
              color: #05A081;
            }
          }

          .arco-link {
            color: #05A081;
            margin-left: 10px;
          }

          &:last-child {
            margin-bottom: 0px;
          }

          .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

        }
      }

      .item_right {
        width: 160px;
        font-size: 22px;
        font-weight: 700;
        color: #ff8139;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .arco-empty {
      padding: 100px 0;
      border-bottom: 1px solid #DCDFE6;
    }
  }
</style>