<template>
  <div class="course_teacher">
    <h3>{{ title }}</h3>
    <div class="list">
      <div class="item" v-for="item in teacherList" :key="item.id" @click="teacherClick(item.id)">
        <img :src="baseUrl + item.photoUrl" />
        <div>
          <h4>{{ item.teacherName }}</h4>
          <p>{{ type == 'institution' ? `课程数量 ${item.courseNumber || 0}` : item.lecturerProfile }}</p>
        </div>
      </div>
      <div v-if="!type && teacherList.length == 1 && $route.name == 'trainingCoursesDetail'" class="expand">
        <p>已发布 <span>{{ teacherList[0].courseNumber }}</span> 个课程</p>
        <div class="course_list">
          <div class="course_item" v-for="(line, index) in teacherList[0].courseList.slice(0, 3)" :key="index" @click="courseClick(line.id)">
            <span>{{ index + 1 }}</span>
            <p>{{ line.courseName }}</p>
          </div> 
        </div>
      </div>
      <div class="expand" v-if="type == 'institution'">
        <div class="list">
          <div class="item">
            <img :src="baseUrl + organization.logoUrl" />
            <div>
              <h4>{{ organization.organizationName }}</h4>
              <p>课程数量 {{ organization.courseNumber || 0}}</p>
            </div>
          </div>
        </div>
        <p style="line-height: 24px;">{{ organization.briefIntroduction }}</p>
        <a-button type="outline" @click="jiClick">查看机构主页</a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'type', 'teacherList', 'organization'],
  data() {
    return {
    }
  },
  methods: {
    teacherClick(id = 0) {
      window.open(`/teacherDetail/${id}`)
    },
    courseClick(id = 0) {
      window.open(`/trainingCoursesDetail/${id}`)
    },
    jiClick(id = 0) {
      window.open(`/institutionDetail/${this.organization.id}`)
    },
  }
}
</script>

<style lang='scss'>
  .course_teacher {
    padding-bottom: 20px;

    & > h3 {
      padding: 15px 10px;
      font-size: 18px;
      color: #303133;
      line-height: 26px;
    }

    .list {
      .item {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;

        &:hover {
          background: #f7f7f7;
        }

        & > img {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          margin-right: 12px;
        }

        & > div {
          & > h4 {
            font-size: 16px;
            color: #303133;
            line-height: 22px;
            margin-bottom: 4px;
          }

          & > p {
            font-size: 12px;
            color: #86909c;
            text-overflow: ellipsis;
            overflow:hidden;
			    	white-space: nowrap;
            width: 190px;
          }
        }
      }
    }

    .expand {
      & > p {
        padding: 10px;
        font-size: 14px;
        color: #4e5969;   
        line-height: 20px;

        & > span {
          color: #303133;
          font-weight: 600;
        }
      }

      .course_item {
        padding: 11px;
        display: flex;
        line-height: 20px;
        cursor: pointer;

        &:hover {
          background: #FAFAFA;
        }

        & > span {
          display: block;
          width: 20px;
          height: 20px;
          font-size: 12px;
          text-align: center;
          color: #ffffff;
          background: #FF8139;
          margin-right: 11px;
          flex-shrink: 0;
          border-radius: 2px;
        }

        & > p {
          font-size: 14px;
          color: #303133;
          text-overflow: ellipsis;
          overflow:hidden;
          white-space: nowrap;
          width: 230px;
          flex-shrink: 0;
        }

      }

      .arco-btn-outline {
        width: 100%;
        margin: 20px 0 0; 
      }
    }
  }
</style>